<template>
  <div>
    <a-row type="flex" justify="center" style="margin-left: -10%; min-height: calc(100vh - 30px);">
      <a-form style="width: 800px; margin: 0 auto" :form="form" @submit="submit" :colon="false">
        <a-form-item v-bind="formItemLayout" label=" ">
          <h1 style="text-align: center; padding-top: 70px">加盟商注册</h1>
        </a-form-item>
        <a-form-item v-bind="formItemLayout" label=" ">
          <a-steps :current="step">
            <a-step
              v-for="(item,index) in steps"
              :key="index"
              :title="item.title"
              @click="goBackStep(index)"
            >
              <a-icon slot="icon" :type="item.icon" />
            </a-step>
          </a-steps>
        </a-form-item>
        <a-form-item v-bind="formItemLayout" label=" ">
          <a-alert message="温馨提示：本商城不支持个人注册。如您已有登录账号，请勿重复注册。" banner />
        </a-form-item>
        <a-form-item v-bind="formItemLayout" label=" " v-if="step==2">
          <a-icon
            style="display:flex;justify-content:center;fontSize:100px;color:#67c23a"
            type="check-circle"
          />
          <h2 style="text-align: center">注册提交成功，通过审核后生效。</h2>
          <h4 style="text-align: center">如果审核未通过并有疑问，请联系专属开发人员或致电4001689282。</h4>
        </a-form-item>
        <a-form-item v-if="step==1" v-bind="formItemLayout" label="门店名称" required>
          <a-select :default-value="hotel_name" placeholder="门店名称请与合同一致" @change="handelChange">
            <a-select-option
              v-for="(item,index) in dataFranchisee"
              :key="index"
              :value="index"
            >{{item.hotel_name}}</a-select-option>
          </a-select>
          <!-- <a-input v-decorator="['hotel_name', { rules }]" placeholder="门店名称请与合同一致" /> -->
        </a-form-item>
        <a-form-item v-if="step==1" v-bind="formItemLayout" label="登录用户名" required>
          <a-input v-model="name" placeholder="加盟商名称或负责人姓名" />
        </a-form-item>
        <a-form-item v-if="step==0" v-bind="formItemLayout" label="绑定手机号">
          <a-input
            addon-before="+86"
            placeholder="绑定手机号用于登录及找回密码"
            :maxLength="11"
            style="width: 100%"
            v-decorator="['phone', { rules: [
              { required: true, message: '请填写手机号' },
              { len: 11, message: '手机号长度仅限 11 位' },
            ] }]"
          />
        </a-form-item>
        <a-form-item v-if="step==0" v-bind="formItemLayout" label="验证码">
          <a-row :gutter="8">
            <a-col :span="15">
              <a-input v-decorator="['verification', { rules }]" placeholder="请输入短信验证码" />
            </a-col>
            <a-col :span="5">
              <a-button
                :disabled="disabled"
                @click="getCode"
              >获取验证码{{ countDown>0 ? `（${countDown}）` : '' }}</a-button>
            </a-col>
          </a-row>
        </a-form-item>
        <a-form-item v-if="step==1" v-bind="formItemLayout" label="设置登录密码">
          <a-input v-decorator="['password', { rules }]" placeholder="用于登录舒悦商城加盟商系统" />
        </a-form-item>
        <a-form-item v-if="step==1" v-bind="formItemLayout" label="再次确认密码">
          <a-input v-decorator="['password_again', { rules }]" placeholder="密码需与登录密码一致" />
        </a-form-item>
        <a-form-item v-if="step==1" v-bind="formItemLayout" label="股东姓名">
          <a-input v-decorator="['id_card_name', { rules }]" placeholder="股东姓名请与合同一致" />
        </a-form-item>
        <a-form-item v-if="step==1" v-bind="formItemLayout" label="股东身份证" required>
          <a-upload
            list-type="picture-card"
            :file-list="fileList"
            :beforeUpload="imageAdd"
            :remove="imageRemove"
            @preview="imagePreview"
            @change="imageChange"
          >
            <div v-if="fileList.length < 2">
              <a-icon type="plus" />
              <div v-if="fileList.length == 0" class="ant-upload-text">上传人像面</div>
              <div v-if="fileList.length == 1" class="ant-upload-text">上传国徽面</div>
            </div>
          </a-upload>
        </a-form-item>
        <a-form-item v-if="step==0 || step==1" v-bind="tailFormItemLayout">
          <a-checkbox v-model="isEnableSumit">
            我已同意舒悦商城
            <a href="https://store.chamjoy.com/zhucexieyi.html" target="_blank">注册政策</a>
            <span style="margin-left:10px">和</span>
            <a
              href="https://store.chamjoy.com/yinsizhengche.html"
              target="_blank"
              style="margin-left:10px"
            >隐私协议</a>
          </a-checkbox>
        </a-form-item>
        <a-form-item v-if="step==0" v-bind="tailFormItemLayout">
          <div style="text-align: center">
            <a-button
              :disabled="!isEnableSumit"
              type="primary"
              size="large"
              style="width: 100%"
              html-type="submit"
            >{{ isEnableSumit ? "下一步" : '请勾选同意用户政策' }}</a-button>
          </div>
        </a-form-item>
        <a-form-item v-if="step==1" v-bind="tailFormItemLayout">
          <div style="text-align: center">
            <a-button
              :disabled="!isEnableSumit"
              :loading="spinning"
              type="primary"
              size="large"
              style="width: 100%"
              html-type="submit"
            >{{ isEnableSumit ? "提交申请" : '请勾选同意用户政策' }}</a-button>
          </div>
        </a-form-item>
        <a-form-item v-bind="tailFormItemLayout">
          <a-row type="flex" justify="space-between" align="middle">
            <div style="display: flex; align-items: flex-start">
              <a-button type="link" size="small" @click="$router.replace('/login')">
                已有账户，立即
                <a style="color:#000">登录</a>
              </a-button>
            </div>
            <div style="display: flex; flex-direction: column">
              <a-dropdown>
                <a-menu slot="overlay" @click="menuClick">
                  <a-menu-item key="https://store.chamjoy.com/builder">施工方注册</a-menu-item>
                  <a-menu-item key="https://store.chamjoy.com/supplier">供应商注册</a-menu-item>
                </a-menu>
                <a-button type="link">
                  注册其它用户
                  <a-icon type="down" />
                </a-button>
              </a-dropdown>
            </div>
          </a-row>
        </a-form-item>
      </a-form>
    </a-row>
    <div style="width:100%; height:30px">
      <h5 style="width: 650px; margin: 0 auto;">
        <copyright />
      </h5>
    </div>
    <!-- 图片预览 -->
    <a-modal :visible="previewVisible" :footer="null" @cancel="imageCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
import Copyright from '@/component/home/Copyright.vue'
const rules = [{ required: true, message: '必填字段' }]
var images = [] // 存储上传的图片
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}
export default {
  components: {
    Copyright
  },

  data() {
    return {
      rules,
      spinning: false,
      isEnableSumit: false,
      countDown: 0, // 验证码倒计时
      disabled: false, // 验证码按钮禁用
      autoCompleteResult: [],
      formItemLayout: {
        labelCol: { sm: { span: 6 } },
        wrapperCol: { sm: { span: 18 } }
      },
      tailFormItemLayout: {
        wrapperCol: { sm: { offset: 6 } }
      },
      fileList: [], // 上传图片列表
      previewVisible: false, // upload 图片预览
      previewImage: '',
      dataFranchisee: [], // 门店信息
      name: '',
      hotel_name: '',
      step: 0, // 步骤
      steps: [
        {
          title: '验证手机号',
          icon: 'mobile'
        },
        {
          title: '填写基础信息',
          icon: 'file-text'
        },
        {
          title: '提交审核',
          icon: 'check-circle'
        }
      ],
      phone: ''
    }
  },

  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'register' })
    this.get('getAllFranchiseeForFranchisee', { status: 5 }).then((res) => {
      this.dataFranchisee = res
    })
  },

  methods: {
    handelChange(e) {
      this.hotel_name = this.dataFranchisee[e].hotel_name
    },
    goBackStep(index) {
      if (index < this.step) {
        this.step = index
      }
    },
    getCode() {
      this.form.validateFields(['phone'], (err, values) => {
        if (!err) {
          this.disabled = true
          this.post('sendSMS', {
            phone: values.phone
          })
            .then(() => {
              this.$message.success('发送成功，请查收')
              let countDown = 60
              const timer = setInterval(() => {
                this.countDown = countDown
                if (countDown-- === 0) {
                  clearInterval(timer)
                  this.disabled = false
                }
              }, 1000)
            })
            .catch((err) => {
              this.$message.error(err.msg)
              this.disabled = false
            })
        }
      })
    },
    submit(e) {
      e.preventDefault()
      if (this.step === 0) {
        this.form.validateFieldsAndScroll((err, values) => {
          if (err) {}
          this.spnning = true
          this.phone = values.phone
          this.post('verifyPhoneForFranchisee', {
            phone: values.phone,
            verification: values.verification
          }).then((res) => {
            this.name = res.name
            this.hotel_name = res.hotel_name
            this.step = 1
          }).catch(err => {
            this.$message.error(err.msg)
            this.spnning = false
          })
        })
      } else {
        if (images.length === 0) return this.$message.warn('请上传身份证人像面')
        if (images.length === 1) return this.$message.warn('请上传身份证国徽面')
        this.form.validateFieldsAndScroll((err, values) => {
          if (values.password !== values.password_again) return this.$message.warn('两次密码输入不一致')
          values.name = this.name
          values.hotel_name = this.hotel_name
          values.phone = this.phone
          if (!err) {
            this.spnning = true
            this.upload('uploadIDCard', images).then((url) => {
              values.id_card_person = url[0]
              values.id_card_cover = url[1]
              this.post('registerForFranchisee', values)
                .then(() => {
                  images = []
                  this.$info({
                    icon: 'clock-circle',
                    title: '申请已提交审核',
                    content:
                      '审核结果将在 5 工作日内以短信形式发送至绑定手机号。',
                    okText: '确定',
                    onOk: () => {
                      this.step = 2
                      // this.$router.go(-1)
                    }
                  })
                })
                .catch((err) => {
                  this.$message.error(err.msg)
                  this.spnning = false
                })
            })
          }
        })
      }
    },
    menuClick(e) {
      window.open(e.key, '_blank')
    },
    // 和图片上传有关
    async imagePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    imageCancel() {
      this.previewVisible = false
    },
    imageChange({ fileList }) {
      this.fileList = fileList
    },
    imageAdd(file) {
      images.push(file)
      return false
    },
    imageRemove(file) {
      this.deleteFile(file, this.fileList)
      this.deleteFile(file, images)
      return false
    },
    deleteFile(file, res) {
      res.splice(res.indexOf(res.find((item) => item.uid === file.uid)), 1)
      if (res.length === 0) res = []
    }
  }
}
</script>

<style scoped>
.ant-form-item {
  margin-bottom: 15px;
}
</style>
